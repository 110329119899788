@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

body{
    background-color: rgba(42, 1, 72, 1);
}
.ubuntu-light {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: normal;
  }
  
  .ubuntu-regular {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .ubuntu-medium {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: normal;
  }
  
  .ubuntu-bold {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .ubuntu-light-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 300;
    font-style: italic;
  }
  
  .ubuntu-regular-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .ubuntu-medium-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 500;
    font-style: italic;
  }
  
  .ubuntu-bold-italic {
    font-family: "Ubuntu", sans-serif;
    font-weight: 700;
    font-style: italic;
  }
  
